import React from "react"
import { Box } from "@chakra-ui/core"

type Props = {}

export const SidebarRight: React.FC<Props> = ({ children }) => {
  return (
    <Box flex="2" marginX="auto">
      {children}
    </Box>
  )
}
