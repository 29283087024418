import { Box, Heading } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React from "react"
import rehypeReact from "rehype-react"
import { CVad } from "../../components/ads/CvAd"
import { CompareHeader } from "../../components/compare/header/CompareHeader"
import { MainContainer } from "../../components/containers/mainContainer/MainContainer"
import { FixedSidebar } from "../../components/containers/sideBars/fixedSidebar/FixedSidebar"
import { SidebarRight } from "../../components/containers/sideBars/SidebarRight"
import { TextContainer } from "../../components/containers/textContainer/TextContainer"
import { Layout } from "../../components/layout/Layout"
import { Seo } from "../../components/seo/Seo"
import { SuggestContainer } from "../../components/suggest/SuggestContainer"
import { useChildren } from "../../hooks/useChildren"
import { useRegions } from "../../hooks/useRegions"

type Props = {
  data: any
}
// @ts-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const Template: React.FC<Props> = ({ data }) => {
  const {
    title,
    metaTitle,
    metaDesc,
    sidebar,
    slug,
  } = data.markdownRemark.frontmatter

  const regions = useRegions().map(({ node }) => {
    return { name: node.frontmatter.name, link: node.frontmatter.path }
  })
  const children = useChildren(slug)

  //   const productSnippets = products.map((p, i) => {
  //     return `{
  //   "@context" : "http://schema.org",
  //   "@type" : "Product",
  //   "name" : "${p.node.name}",
  //   "image" : "https:${p.node.img.fluid.src}",
  //   "url" : "${p.node.link}",
  //   "offers" : {
  //     "@type" : "Offer",
  //     "price" : "${p.node.price}"
  //   },
  //   "aggregateRating" : {
  //     "@type" : "AggregateRating",
  //     "ratingValue" : "${p.node.rate}",
  //     "ratingCount" : "1"
  //   }
  // } ${i + 1 < products.length ? "," : ""}`
  //   })

  return (
    <Layout>
      <Seo title={metaTitle} desc={metaDesc} />
      <Box>
        <CompareHeader
          bookingId={null}
          lomarengasId="province_000"
          place="Suomi"
          region={false}
        />
      </Box>
      <CVad />
      <MainContainer>
        <Box flex="8">
          <SuggestContainer
            title="Tutustu muunmuassa näihin maakuntiin"
            places={regions}
          />
          <TextContainer>
            {renderAst(data.markdownRemark.htmlAst)}
          </TextContainer>
        </Box>
        {sidebar && (
          <SidebarRight>
            <FixedSidebar>
              <Heading as="h3">Sivupalkki</Heading>
            </FixedSidebar>
          </SidebarRight>
        )}
      </MainContainer>
      {/* <!-- JSON-LD markup generated by Google Structured Data Markup Helper. --> */}
      {/* <script type="application/ld+json">[{productSnippets}]</script> */}
    </Layout>
  )
}

export const query = graphql`
  query($filter: String!) {
    markdownRemark(frontmatter: { path: { eq: $filter } }) {
      htmlAst
      html
      timeToRead
      frontmatter {
        title
        sidebar
        slug
        metaTitle
        metaDesc
      }
    }
  }
`

export default Template
